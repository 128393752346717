@import url(//fonts.googleapis.com/css?family=Lato:300:400);

body {
	margin: 0;
}

h1 {
	font-family: "Lato", sans-serif;
	font-weight: 300;
	letter-spacing: 2px;
	font-size: 48px;
}
p {
	font-family: "Lato", sans-serif;
	letter-spacing: 1px;
	font-size: 14px;
	color: #333333;
}

/* .header {
  position:relative;
  text-align:center;
  background: linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%);
  color:white;
} */

/* .flex { 
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
} */

/* .waves {  
  width: 100%;
  height:25vh;
  min-height:100px;
  max-height:150px;
  bottom: 0;
  position: absolute;
}  */

.steps-content {
	min-height: 200px;
	margin-top: 16px;
	padding-top: 80px;
	text-align: center;
	background-color: #fafafa;
	border: 1px dashed #e9e9e9;
	border-radius: 2px;
}

.steps-action {
	margin-top: 24px;
}
[data-theme="dark"] .steps-content {
	margin-top: 16px;
	border: 1px dashed #303030;
	background-color: rgba(255, 255, 255, 0.04);
	color: rgba(255, 255, 255, 0.65);
	padding-top: 80px;
}

.content {
	position: relative;
	height: 20vh;
	text-align: center;
	background-color: white;
}

button,
input[type="submit"] {
	background: #6080f5;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	border: none;
	margin-top: 1px;
	padding: 15px;
	font-size: 14px;
	font-weight: 500;
	width: fit-content;
}

/* Animation */

.parallax > use {
	animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
	animation-delay: -2s;
	animation-duration: 7s;
}
.parallax > use:nth-child(2) {
	animation-delay: -3s;
	animation-duration: 10s;
}
.parallax > use:nth-child(3) {
	animation-delay: -4s;
	animation-duration: 13s;
}
.parallax > use:nth-child(4) {
	animation-delay: -5s;
	animation-duration: 20s;
}
@keyframes move-forever {
	0% {
		transform: translate3d(-90px, 0, 0);
	}
	100% {
		transform: translate3d(85px, 0, 0);
	}
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
	.waves {
		height: 40px;
		min-height: 40px;
	}
	.content {
		height: 30vh;
	}
	h1 {
		font-size: 24px;
	}
}

.signup-container {
	width: 70%;
	@media screen and (max-width: 800px) {
		width: 100%;
	}
}
