.App {
	text-align: center;
	max-width: 100%;
	max-height: 100%;
	overflow: hidden;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.ant-upload-list-item-error {
	border-color: white;
}

.ant-upload-list-picture-card-container {
	float: left;
}

thead[class*="ant-table-thead"] th {
	background-color: #f8fbff !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

input::-webkit-input-placeholder {
	/* WebKit browsers */
	color: rgb(149, 171, 211);
}
input:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: rgb(149, 171, 211);
}
input::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: rgb(149, 171, 211);
}
input:-ms-input-placeholder {
	/* Internet Explorer 10+ */
	color: rgb(149, 171, 211);
}

.ant-upload-text-icon,
.ant-upload-list-item-info {
	display: none;
}


@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(360deg);
	}
}
@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.recharts-legend-item {
	margin-bottom: 5px;
}

.recharts-legend-item-text {
	padding: 5px;
	text-transform: capitalize;
	font-weight: 500;
}
