@import url(//fonts.googleapis.com/css?family=Lato:300:400);
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* controlling the form card for antd-card upload: Styles are global */
.ant-upload-picture-card-wrapper.avatar-uploader {
	width: 100%;
	height: 100%;
	margin-right: 1rem;
}

/* controlling the form card for antd-card upload:  Styles are global */
.ant-upload.ant-upload-select.ant-upload-select-picture-card {
	width: 100%;
	height: 100%;
}

.ant-input-number {
	width: 100% !important;
}

.ant-popover-inner {
	border-radius: 3px !important;
}

.ant-tabs-tab {
	padding: 9px 12px !important;
}
.ant-tabs.ant-tabs-top {
	width: 100%;
}

.ant-tabs-top.ant-tabs-nav.ant-tabs-bottom {
	margin: 0px;
}

.step-two-container {
	height: 94%;
	padding: 1rem;
}
.step-two-radio {
	padding: 1em;
}
.step-two-button-container {
	padding: 10px;
	display: flex;
	justify-content: space-evenly;
}

.pt-cursor {
	cursor: pointer;
}

.ant-tabs-top.ant-tabs-nav {
	margin: 0px 0px 222px 0px;
}

.icon-wrapper {
	position: relative;
	padding: 0px 30px;
	width: 175px;
}

.icon-wrapper .anticon {
	position: absolute;
	top: -2px;
	width: 16px;
	height: 16px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 16px;
	line-height: 1;
}

.icon-wrapper .icon-wrapper-active {
	color: #1890ff;
}

.icon-wrapper .anticon:first-child {
	left: 0;
}

.icon-wrapper .anticon:last-child {
	right: 0;
}
.line-wrapper {
	width: 100%;
	text-align: center;
	border-bottom: 0.5px solid #2a2a2a78;
	line-height: 0.1em;
	margin: 10px 0 20px;
}

.line-wrapper .span-wrapper {
	background: #fff;
	padding: 0 10px;
	color: #2a2a2a78;
}
.login-with-google-btn {
	transition: background-color 0.3s, box-shadow 0.3s;

	padding: 12px 16px 12px 42px;
	border: 0.1px solid #2a2a2a2e;
	border-radius: 3px;
	box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

	color: #757575;
	font-size: 14px;
	font-weight: 500;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
		Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
	background-color: white;
	background-repeat: no-repeat;
	background-position: 13px 13px;
	cursor: pointer;
}

.login-with-google-btn:hover {
	box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}

.ant-popover-inner-content {
	padding: 0px !important;
}

body {
	margin: 0;
}

h1 {
	font-family: "Lato", sans-serif;
	font-weight: 300;
	letter-spacing: 2px;
	font-size: 48px;
}
p {
	font-family: "Lato", sans-serif;
	letter-spacing: 1px;
	font-size: 14px;
	color: #333333;
}

/* .header {
  position:relative;
  text-align:center;
  background: linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%);
  color:white;
} */

/* .flex { 
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
} */

/* .waves {  
  width: 100%;
  height:25vh;
  min-height:100px;
  max-height:150px;
  bottom: 0;
  position: absolute;
}  */

.steps-content {
	min-height: 200px;
	margin-top: 16px;
	padding-top: 80px;
	text-align: center;
	background-color: #fafafa;
	border: 1px dashed #e9e9e9;
	border-radius: 2px;
}

.steps-action {
	margin-top: 24px;
}
[data-theme="dark"] .steps-content {
	margin-top: 16px;
	border: 1px dashed #303030;
	background-color: rgba(255, 255, 255, 0.04);
	color: rgba(255, 255, 255, 0.65);
	padding-top: 80px;
}

.content {
	position: relative;
	height: 20vh;
	text-align: center;
	background-color: white;
}

button,
input[type="submit"] {
	background: #6080f5;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	border: none;
	margin-top: 1px;
	padding: 15px;
	font-size: 14px;
	font-weight: 500;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}

/* Animation */

.parallax > use {
	-webkit-animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
	        animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
	-webkit-animation-delay: -2s;
	        animation-delay: -2s;
	-webkit-animation-duration: 7s;
	        animation-duration: 7s;
}
.parallax > use:nth-child(2) {
	-webkit-animation-delay: -3s;
	        animation-delay: -3s;
	-webkit-animation-duration: 10s;
	        animation-duration: 10s;
}
.parallax > use:nth-child(3) {
	-webkit-animation-delay: -4s;
	        animation-delay: -4s;
	-webkit-animation-duration: 13s;
	        animation-duration: 13s;
}
.parallax > use:nth-child(4) {
	-webkit-animation-delay: -5s;
	        animation-delay: -5s;
	-webkit-animation-duration: 20s;
	        animation-duration: 20s;
}
@-webkit-keyframes move-forever {
	0% {
		transform: translate3d(-90px, 0, 0);
	}
	100% {
		transform: translate3d(85px, 0, 0);
	}
}
@keyframes move-forever {
	0% {
		transform: translate3d(-90px, 0, 0);
	}
	100% {
		transform: translate3d(85px, 0, 0);
	}
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
	.waves {
		height: 40px;
		min-height: 40px;
	}
	.content {
		height: 30vh;
	}
	h1 {
		font-size: 24px;
	}
}

.signup-container {
	width: 70%;
	@media screen and (max-width: 800px) {
		width: 100%;
	}
}

.App {
	text-align: center;
	max-width: 100%;
	max-height: 100%;
	overflow: hidden;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		-webkit-animation: App-logo-spin infinite 20s linear;
		        animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.ant-upload-list-item-error {
	border-color: white;
}

.ant-upload-list-picture-card-container {
	float: left;
}

thead[class*="ant-table-thead"] th {
	background-color: #f8fbff !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

input::-webkit-input-placeholder {
	/* WebKit browsers */
	color: rgb(149, 171, 211);
}
input:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	color: rgb(149, 171, 211);
}
input::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	color: rgb(149, 171, 211);
}
input:-ms-input-placeholder {
	/* Internet Explorer 10+ */
	color: rgb(149, 171, 211);
}

.ant-upload-text-icon,
.ant-upload-list-item-info {
	display: none;
}
@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}

.recharts-legend-item {
	margin-bottom: 5px;
}

.recharts-legend-item-text {
	padding: 5px;
	text-transform: capitalize;
	font-weight: 500;
}

