body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* controlling the form card for antd-card upload: Styles are global */
.ant-upload-picture-card-wrapper.avatar-uploader {
	width: 100%;
	height: 100%;
	margin-right: 1rem;
}

/* controlling the form card for antd-card upload:  Styles are global */
.ant-upload.ant-upload-select.ant-upload-select-picture-card {
	width: 100%;
	height: 100%;
}

.ant-input-number {
	width: 100% !important;
}

.ant-popover-inner {
	border-radius: 3px !important;
}

.ant-tabs-tab {
	padding: 9px 12px !important;
}
.ant-tabs.ant-tabs-top {
	width: 100%;
}

.ant-tabs-top.ant-tabs-nav.ant-tabs-bottom {
	margin: 0px;
}

.step-two-container {
	height: 94%;
	padding: 1rem;
}
.step-two-radio {
	padding: 1em;
}
.step-two-button-container {
	padding: 10px;
	display: flex;
	justify-content: space-evenly;
}

.pt-cursor {
	cursor: pointer;
}

.ant-tabs-top.ant-tabs-nav {
	margin: 0px 0px 222px 0px;
}

.icon-wrapper {
	position: relative;
	padding: 0px 30px;
	width: 175px;
}

.icon-wrapper .anticon {
	position: absolute;
	top: -2px;
	width: 16px;
	height: 16px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 16px;
	line-height: 1;
}

.icon-wrapper .icon-wrapper-active {
	color: #1890ff;
}

.icon-wrapper .anticon:first-child {
	left: 0;
}

.icon-wrapper .anticon:last-child {
	right: 0;
}
.line-wrapper {
	width: 100%;
	text-align: center;
	border-bottom: 0.5px solid #2a2a2a78;
	line-height: 0.1em;
	margin: 10px 0 20px;
}

.line-wrapper .span-wrapper {
	background: #fff;
	padding: 0 10px;
	color: #2a2a2a78;
}
.login-with-google-btn {
	transition: background-color 0.3s, box-shadow 0.3s;

	padding: 12px 16px 12px 42px;
	border: 0.1px solid #2a2a2a2e;
	border-radius: 3px;
	box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

	color: #757575;
	font-size: 14px;
	font-weight: 500;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
		Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
	background-color: white;
	background-repeat: no-repeat;
	background-position: 13px 13px;
	cursor: pointer;
}

.login-with-google-btn:hover {
	box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}

.ant-popover-inner-content {
	padding: 0px !important;
}
